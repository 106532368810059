import React, {useEffect, useState} from "react";
import ProviderAuth from "./routers/ProviderAuth";
import {LoginAcc} from "./pages/authentication/LoginAcc/LoginAcc";
import {
    Switch,
    Redirect,
    BrowserRouter as Router,
    Route,
} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    getUserDataThunk, setIsLoginUser,
} from "./redux/reducers/userAuth/sliceUserAuth";
import PublicRouter from "./routers/PublicRouter";
import {isLoginSelector} from "./redux/selectors/selectors";
import ScrollToTop from "./Constants/ScrollToTop";
import ResetPassword from "./pages/authentication/ResetPassword";
import PrivateRoute from "./routers/PrivateRouter";
import ProfileNavigation from "./pages/Profile/ProfileNavigation";
import PrivateRouterNavigation from "./routers/PrivateRouterNavigation";
import PageNotFound from './Components/pageNotFound/PageNotFound'
import Keys from "./Constants/helper";
import {ThemeProvider, createTheme} from "@material-ui/core/styles";
import './i18n/config';
import EmailVerification from "./pages/authentication/EmailVerification";
import {HelmetProvider} from "react-helmet-async";
import Header from "./pages/header";
import Footer from "./pages/footer/Footer";
import {APINotifications} from "./API/api";
import MySwal from "sweetalert2";
import {useTranslation} from "react-i18next";

export const createAd = "/createAd";

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
}

const theme = createTheme({
    typography: {
        fontFamily: ['Montserrat', 'Inter',].join(','),
    },
})



const App = () => {
    const isLogin = useSelector(isLoginSelector);
    const dispatch = useDispatch();
    const [isNotificationSupported, setIsNotificationSupported] = useState(false);
    const {t} = useTranslation()

    const checkSupport = () => {
        if ('Notification' in window && 'serviceWorker' in navigator) {
          setIsNotificationSupported(true);
        } else {
          setIsNotificationSupported(false);
        }
      };


    const handlePermissionChange = (newPermission) => {
          if (newPermission === 'denied') {
            handlePermissionDenied();
          } else if (newPermission === 'granted') {
            subscribeToNotifications();
          }
      };


    const showNotificationPopup = () => {
        MySwal.fire({
          title: t('notification.Stay Informed!'), // Zostaňte informovaní!',
          text: t("notification.Allow notifications so you never miss an update."), // Povoľte upozornenia, aby vám už nič neuniklo.",
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: t('notification.Manage Notifications'), // Spravovať notifikácie',
        }).then((result) => {
          if (result.isConfirmed) {
            requestNotificationPermission();
          }
        });
      };

    const subscribeToNotifications = () => {
        if (isLogin && 'serviceWorker' in navigator && 'PushManager' in window) {
            navigator.serviceWorker.register('/service-worker.js').then(registration => {
                    console.log('Service Worker registered with scope:', registration.scope);
                    return registration.pushManager.getSubscription().then(subscription => {
                        if (subscription) {
                            return subscription;
                        }
                        const publicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;  // Replace with your VAPID public key
                        return registration.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: urlBase64ToUint8Array(publicKey)
                        });
                    });
                }).then(subscription => {
                    console.log('User is subscribed:', subscription);
                    // Send the subscription to your server
                    APINotifications.subscribe(subscription)
                }).then(response => response)
                .then(data => {
                    console.log('Subscription saved:', data);
                })
                .catch(error => {
                    console.error('Failed to subscribe the user:', error);
                    if (error.name === "NotAllowedError" && error.message.includes("only be done from a user gesture")) {
                      showNotificationPopup();
                    }
                });
        }
    }

    useEffect(() => {
        if (!isNotificationSupported || !isLogin) return;
          subscribeToNotifications()
    }, [isNotificationSupported, isLogin]);


      const requestNotificationPermission = () => {
          Notification.requestPermission().then((permission) => {
            handlePermissionChange(permission);
          });
        };

    const handlePermissionDenied = () => {
        navigator.serviceWorker.ready.then((registration) => {
                return registration.pushManager.getSubscription().then((subscription) => {
                    if (subscription) {
                        return subscription.unsubscribe().then(() => {
                            // Notify the server to remove the subscription using Axios
                            APINotifications.unsubscribe(subscription)
                        });
                    }
                });
            }).then((response) => {
                console.log('Unsubscribed:', response);
            }).catch((error) => {
                console.error('Failed to unsubscribe:', error);
            });
      };

    const appHeight = () => {
        document.documentElement.style.setProperty(
            "--app-height",
            `${window.innerHeight}px`
        );
    };


    useEffect(() => {
        appHeight();
        window.addEventListener("resize", appHeight);
        checkSupport()

        return () => window.removeEventListener("resize", appHeight);
    }, []);

    useEffect(() => {
        let refreshToken = localStorage.getItem(Keys.REFRESH_TOKEN);
        if (refreshToken) {
            dispatch(setIsLoginUser());
        }
    }, [dispatch]);

    useEffect(() => {
        if (isLogin) {
            dispatch(getUserDataThunk())
        } else {
            handlePermissionDenied()
        }
    }, [isLogin, dispatch]);

    return (
        <HelmetProvider>
            <ThemeProvider theme={theme}>
                <ProviderAuth>
                    <Router>
                        <ScrollToTop/>
                        <Header/>
                        <Switch>
                            <Route exact path={"/auth"} component={LoginAcc}/>
                            <Route exact path={"/reset-password"} component={ResetPassword}/>
                            <Route exact path={"/verify-email"} component={EmailVerification}/>
                            <Route exact path={"/"} component={PublicRouter}/>
                            <Route exact path={"/ads"} component={PublicRouter}/>
                            <Route exact path={"/pricing"} component={PublicRouter}/>

                            <Route
                                exact
                                path={"/ads/detail-ad/:id/:advertising?"}
                                component={PublicRouter}
                            />
                            <Route exact path={"/user-profile/:id"} component={PublicRouter}/>
                            <Route exact path={"/privacy-policy"} component={PublicRouter}/>
                            <Route exact path={"/terms-of-use"} component={PublicRouter}/>
                            <Route exact path={"/createAd"} component={PrivateRouterNavigation}/>
                            <Route
                                exact
                                path={"/ads/edit-ad/:id"}
                                component={PrivateRouterNavigation}
                            />

                            <PrivateRoute path={"/profile/:slug"}>
                                {/*<WrapperApp>*/}
                                <ProfileNavigation/>
                                {/*</WrapperApp>*/}
                            </PrivateRoute>

                            <Route path="*">
                                <PageNotFound/>
                                <Redirect to={"/404"}/>
                            </Route>
                        </Switch>
                        <Footer/>
                    </Router>
                </ProviderAuth>
            </ThemeProvider>
        </HelmetProvider>
    );
};

// export const WrapperApp = ({children}) => {
//     return (
//         <div className={"wrapper_app"}>
//             <Header/>
//             {children}
//             <Footer/>
//         </div>
//     );
// };

export default App;
